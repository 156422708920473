:root{
  --primary: #fff;
}

/* default styles  */
body{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.app_outer{
  background-color: black;
  width: 100%;
  height: 100vh;
}

.app{
  width: 100%;
  height: 100%;
  max-width: 1536px;
  background-color: #2c2c34;
  overflow-y: auto;
}

.navbar{
  width: 100%;
  background-color: #17171f;
  height: 70px;
}

.btn{
  background-color: #6d9ef0;
  padding: 10px;
  border-radius: 10px;
  min-width: 100px;
  color: #000;
}

.text{
  padding: 10px;
  border-radius: 5px;
  outline:0px;
  width: 200px;
  color: #000;
}

.dropdown_list{
  appearance: none;
  outline:0px;
  width: 200px;
  color: #000;
}

.dropdown_list:hover{
  cursor: pointer;
}

input[type="file"]{
  height: 1px;
  width:1px;
}

.custom_file_upload{
   width: 100px;
   display: flex;
   justify-content: center;
   align-items: center;
  background-color: #6d9ef0;
  color:#000;
  border-radius: 10px;
  padding: 10px;
}

.custom_file_upload:hover{
  cursor: pointer;
}

/* custom styles  */

.navbar__title{
  color: var(--primary);
}

.data_source__step1__title{
  color: var(--primary);
}

.data_source__step2__title{
  color: var(--primary);
}

.data_source__step3__title{
  color: var(--primary);
}

@tailwind base;
@tailwind components;
@tailwind utilities;
